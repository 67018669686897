import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, Button, Layout, Row } from 'antd';
import { gray } from '@ant-design/colors';
import { LogoutOutlined, MenuOutlined, CalendarOutlined, FileProtectOutlined, UserOutlined } from '@ant-design/icons';
import { ROUTES } from 'common_constants/routes';

import { setMobSideBar } from '../../store/uiReducer';
import UserAvatar from '../UserAvatar';
import './styles.scss';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { translation } from 'common_constants/translation';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const MainMenu = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.language);
  const userAuth = useSelector((state) => state.common.userAuth);
  const appPrepared = useSelector((state) => state.common.appPrepared);
  const { mobSidebar, mob } = useSelector((state) => state.ui);
  const users = useSelector((state) => state.common.users);
  const currentUser = users?.[userAuth?._id];

  const [selected, setSelected] = useState(pathname);

  const items = [
    getItem(translation.hotlines[lang], ROUTES.LIST, <CalendarOutlined />),
    getItem(translation.contracts[lang], ROUTES.CONTRACTS, <FileProtectOutlined />),
    getItem(translation.profile[lang], ROUTES.PROFILE, <UserOutlined />),
  ];

  const handleClick = (item) => {
    setSelected(item.key);
    history.push(item.key);
  };

  const handleCollapse = (value) => {
    dispatch(setMobSideBar(!value));
  };

  const profile = () => {
    history.push(ROUTES.PROFILE);
  };

  const logout = () => {
    window.localStorage.removeItem('partnerToken');
    window.location.reload();
  };

  const menuSliderRef = useRef(null);

  useEffect(() => {
    setSelected(pathname);
    mob && handleCollapse(true);
  }, [pathname]);

  useEffect(() => {
    if (!userAuth) return;
    if (!appPrepared) return;
  }, []);

  return (
    <>
      <Row className="menu-header">
        <Button type="danger" className="profile" onClick={profile}>
          <UserAvatar user={currentUser} style={{ width: 32, height: 32 }} />
        </Button>
        <Button type="danger" className="logout" onClick={logout}>
          <LogoutOutlined />
        </Button>
      </Row>
      <div ref={menuSliderRef}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          className="sidebar"
          width={300}
          collapsed={!mobSidebar}
          style={{ position: 'fixed', zIndex: 100 }}
          onCollapse={handleCollapse}
          trigger={<Button style={{ background: 'transparent', color: gray[1], border: 'none' }} icon={<MenuOutlined />} />}
        >
          <Link to={ROUTES.LIST} style={{ display: 'block', width: '100%', textAlign: 'center', padding: 8 }}>
            <img src="/logo.svg" alt="zahist" width={40} />
          </Link>

          <LanguageSwitcher />

          <Menu onClick={handleClick} selectedKeys={[selected]} mode="inline" className="sidebar-list" theme="dark" items={items} />
        </Layout.Sider>
      </div>
      {mobSidebar && mob && (
        <div
          className="overlay"
          onClick={() => {
            // if (!mob) return;
            handleCollapse(mobSidebar);
          }}
        ></div>
      )}
    </>
  );
};

export default React.memo(MainMenu);
